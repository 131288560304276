/*
MAKITA STORE
URL: service.makita.co.id
AUTHOR: Nabil Amer Thabit (nbilz//lab//dsign | @nbilz)
EMAIL: nbilz@live.com
CREATE DATE: Aug 7, 2023
UPDATE DATE: Aug 7, 2023
REVISION: 1
NAME: variable.scss
TYPE: SASS Cascading Style Sheet
DESCRIPTION: IndoMak | ...
PALETTE:  1. #FE0000 / rgb(254, 0, 0) (Pure Red / Red)
          2. #0C8190 / rgb(12, 129, 144) (Dark Cyan / Blue Chill)
          3. #0C5160 / rgb(12, 113, 128) (Very Dark Cyan / Deep Sea Green)
*/

$color1: #0C8190;
$color2: #0C5160;
// $color3: #0C7180;
// $color2hover: #0C5160;
// $colorText: #364250;
$colorText: #6D6D6D;
$colorLabel: #8d8d8d;
$colorError: #FE0000;
$colorSuccess: #4A915B;
$spacing: 20px;
$spacing2: $spacing - 5;
$spacing3: $spacing2  - 5;
$spacing4: $spacing3  - 5;
$radius: 20px;
$radius2: $radius - 5;
$radius3: $radius2 - 5;
$radius4: $radius3 - 5;

%textover{
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

%transition{
  transition: all 200ms ease-in-out !important;
  -moz-transition: all 200ms ease-in-out !important;
  -webkit-transition: all 200ms ease-in-out !important;
}